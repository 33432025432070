/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// google font
// @import url("https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;400;500&display=swap");

.am-action-sheet-button-list-item {
  color: #1890ff;
}
.am-action-sheet-cancel-button {
  color: red;
}

.am-modal-transparent {
  width: calc(100vw - 20px) !important;
  padding: 0px 0px !important;
}
.am-modal-transparent .am-modal-content .am-modal-body {
  padding: 0px 0px !important;
}

.clip-circle {
  /* circle */
  clip-path: circle(50% at 50% 50%);
}
